export const MY_RENDERER_STATUS = {
  unsubscribed: {
    id: 0,
  },
  subscribed: {
    id: 1,
  },
  suspend: {
    id: 2,
  },
}
